import request from "@/utils/request";
import qs from "qs";
/**
 * 查看增值业务订单列表
 */
export function queryOrderList(data = {}, pagesize = 20, current = 1) {
  return request({
    url: `/sys/appreciationNewOrder/queryLists?pageSize=${pagesize}&currentPage=${current}`,
    method: "post",
    data,
  });
}

/**
 * 取消订单
 */
export function cencelOrder(data = {}) {
  return request({
    url: `/sys/appreciationNewOrder/cencel`,
    method: "post",
    data,
  });
}
/**
 * 确认完成
 */
export function finishOrder(id,data) {
  return request({
    url: `/sys/appreciationNewOrder/finish/${id}`,
    method: "post",
    data
  });
  
}
/**
 * 获取取消原因
 */
export function queryCancels() {
  return request({
    url: `/sys/cancelInfo/queryCancels`,
    method: "get",
  });
}
/**
 * 增值统计
 */
export function queryStatistics(data = {}) {
  return request({
    url: `/sys/appreciationNewOrder/queryStatistics`,
    method: "post",
    data,
  });
}
/**
 * 导出增值服务订单
 */
export function exportAppreciationNewOrders(data = {}) {
  const urls = qs.stringify(data);
  return request({
    url: `/sys/appreciationNewOrder/exportAppreciationNewOrders?${urls}`,
    method: "get",
  });
}

/**
 * 查询全部采集任务接
 */
export function queryAllTask(data = {}, pagesize = 20, current = 1) {
  return request({
    url: `/sys/auditInfo/queryAllTask?pageSize=${pagesize}&currentPage=${current}`,
    method: "post",
    data,
  });
}
/**
 * 待审核照片列表
 * @param {*} data
 */
export function queryAuditPhotos(data = {}) {
  return request({
    url: `/sys/auditInfo/queryAuditPhotos`,
    method: "post",
    data,
  });
}
/**
 * 上传处理完成照片 分片
 * @param {?object} data
 * @param {string} data.fileName  文件名
 * @param {number} data.isFace   是否人脸识别（0：否，1：是）
 * @param {string} data.collectType  任务类型
 * @param {string} data.id 任务id
 */
export function uploadFile(data = {}) {
  return request({
    url: `/sys/auditInfo/uploadFile`,
    method: "post",
    data,
  });
}
/**
 * 审核失败照片打包
 * @param {?object} data
 * @param {string} data.collectType  任务类型
 * @param {string} data.id 任务id
 */
export function downloadPhotos(data = {}) {
  return request({
    url: `/sys/auditInfo/downloadPhotos?taskId=${data.id}&collectType=${data.collectType}`,
    method: "get",
  });
}
/**
 * 审核照片
 * @param {?object} data
 * @param {string} data.auditSuccess  审核通过ids，用逗号隔开
 * @param {string} data.auditFailes  审核不通过ids，用逗号隔开
 * @param {string} data.collectType  任务类型
 * @param {string} data.id 任务id
 */
export function auditPhotos(data = {}) {
  return request({
    url: `/sys/auditInfo/auditPhotos`,
    method: "post",
    data,
  });
}
/**
 * 查看处理任务进度
 * @param {?object} data
 * @param {string} data.collectType  任务类型
 * @param {string} data.id 任务id
 */
export function getTaskSchedule(data = {}) {
  return request({
    url: `/sys/auditInfo/query/tasks`,
    method: "post",
    data,
  });
}
/**
 * 审核统计
 * @param {?object} data
 */
export function auditQueryStatistics(data = {}) {
  return request({
    url: `/sys/auditInfo/queryStatistics`,
    method: "post",
    data,
  });
}
/**
 * 获取替换照片任务明细
 * @param {string} id
 */
export function getPhotoDetail(id) {
  return request({
    url: `/sys/auditInfo/photoDetail?id=${id}`,
    method: "get",
  });
}
/**
 * 获取替换照片任务明细
 * @param {?object} data
 * @param {?string} data.id  明细id
 * @param {?string} data.status  处理状态    1替换失败 2替换成功
 */
export function setCheckReplace(data = {}) {
  return request({
    url: `/sys/auditInfo/checkReplace`,
    method: "post",
    data,
  });
}
